import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { RouterLink } from '@sellpy/design-system-react-web'
import { dynamicDescription, user as userTools } from '@sellpy/commons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { gql, useFragment, useMutation, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import {
  HAS_SHOWN_LOGGED_OUT_FAVORITE_MODAL,
  showEditFavoriteModal,
  showLoggedOutFavoriteModal
} from '../../../common/favorites/actions.js'
import { showToast } from '../../../common/ui/actions.js'
import { TOAST_TYPE } from '../../uiComponents/notifications/Toasts.jsx'
import { locale, localizedMetadata } from '../../../common/region/locale.js'
import { getAnalyticsItem } from '../../../common/items/actions.js'
import { analyticsAddToWishlist } from '../../../common/analytics/actions.js'
import { region } from '../../../common/region/region.js'
import { signupAnonymous } from '../../../common/user/actions.js'
import getFavoritesInList from '../../myShopping/pages/favorites/getFavoritesInList.gql'
import { appendNextPath } from '../../routing/url.js'
import getAllFavorites from './getAllFavorites.gql'

const LOCALIZED_METADATA = gql`
  fragment localizedMetadata on Item {
    metadata @include(if: $isSV)
    metadata_de @include(if: $isDE)
    metadata_en @include(if: $isEN)
    metadata_nl @include(if: $isNL)
    metadata_da @include(if: $isDA)
    metadata_pl @include(if: $isPL)
    metadata_fi @include(if: $isFI)
    metadata_fr @include(if: $isFR)
  }
`

const ButtonLink = styled.button`
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  border: 0;
  background: none;
  color: ${({ theme }) => theme.color.blue.default};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const REMOVE_FAVORITE = gql`
  ${LOCALIZED_METADATA}
  mutation removeFavoriteItem(
    $itemId: String!
    $isSV: Boolean!
    $isDE: Boolean!
    $isEN: Boolean!
    $isNL: Boolean!
    $isDA: Boolean!
    $isPL: Boolean!
    $isFI: Boolean!
    $isFR: Boolean!
  ) {
    removeFavoriteItem(itemId: $itemId) {
      objectId
      selected
      active
      item {
        objectId
        ...localizedMetadata
      }
      itemFavoriteLists {
        ... on ItemFavoriteList {
          objectId
          name
          status
        }
      }
    }
  }
`

const ADD_FAVORITE = gql`
  ${LOCALIZED_METADATA}
  mutation addFavoriteItem(
    $itemId: String!
    $region: String
    $isSV: Boolean!
    $isDE: Boolean!
    $isEN: Boolean!
    $isNL: Boolean!
    $isDA: Boolean!
    $isPL: Boolean!
    $isFI: Boolean!
    $isFR: Boolean!
  ) {
    addFavoriteItem(itemId: $itemId, region: $region) {
      createdAt
      objectId
      selected
      active
      item {
        objectId
        ...localizedMetadata
      }
      itemFavoriteLists {
        ... on ItemFavoriteList {
          objectId
          name
          status
        }
      }
    }
  }
`

const EDIT_FAVORITE = gql`
  mutation editFavoriteItem($itemId: ID!, $listIds: [ID]!) {
    editFavoriteItem(itemId: $itemId, listIds: $listIds) {
      objectId
      itemFavoriteLists {
        ... on ItemFavoriteList {
          objectId
        }
      }
    }
  }
`

export const getFormattedNumberOfWishlists = (numberOfWishlists) => {
  if (!numberOfWishlists) return '0'
  return numberOfWishlists > 999
    ? `+${String(numberOfWishlists).charAt(0)}k`
    : String(numberOfWishlists)
}

export const useFavorites = () => {
  const { t } = useTranslation('favorite')
  const dispatch = useDispatch()
  const parseUser = useSelector((state) => state.user.parseUser)
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const { id: favListId } = params
  const { data } = useQuery(getAllFavorites)
  const [editFavorite] = useMutation(EDIT_FAVORITE)
  const [removeFavorite] = useMutation(REMOVE_FAVORITE, {
    onCompleted: (data) => {
      const itemTitle = dynamicDescription.itemTitle({
        metadata: data.removeFavoriteItem.item[localizedMetadata],
        locale
      })
      dispatch(
        showToast({
          header: t('removeFavoriteToast.header', { itemTitle }),
          type: TOAST_TYPE.INFO
        })
      )
    }
  })
  const [addFavorite] = useMutation(ADD_FAVORITE, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: getAllFavorites
        },
        (data) => ({
          itemWishlists: {
            ...data.itemWishlists,
            edges: [...data.itemWishlists.edges, { node: result.data.addFavoriteItem }]
          }
        })
      )
    },
    onCompleted: (data) => {
      const itemId = data.addFavoriteItem.item.objectId
      const itemTitle = dynamicDescription.itemTitle({
        metadata: data.addFavoriteItem.item[localizedMetadata],
        locale
      })
      dispatch(getAnalyticsItem({ itemId })).then((result) => {
        if (result) analyticsAddToWishlist([result])
      })
      // the modal is only shown once per session, for all users except for fully logged-in ones.
      // all toasts are suppressed when the modal is shown.
      let shouldShowLoggedOutFavoriteModal = false
      try {
        shouldShowLoggedOutFavoriteModal =
          !userTools.userIsFull(parseUser) &&
          !window.sessionStorage.getItem(HAS_SHOWN_LOGGED_OUT_FAVORITE_MODAL)
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
      }

      if (shouldShowLoggedOutFavoriteModal) {
        dispatch(showLoggedOutFavoriteModal())
        dispatch(
          showToast({
            header: t('addFavoriteToast.header', { itemTitle }),
            body: (
              <Trans i18nKey='favorite:loginPrompt'>
                text
                <ButtonLink
                  onClick={() => {
                    navigate(appendNextPath('/login')(location.pathname))
                  }}
                  noMargin
                >
                  login
                </ButtonLink>
                <RouterLink to='/signup' noMargin>
                  signup
                </RouterLink>
              </Trans>
            ),
            type: TOAST_TYPE.SUCCESS
          })
        )
      } else {
        dispatch(
          showToast({
            header: t('addFavoriteToast.header', { itemTitle }),
            body: (
              <Trans i18nKey='favorite:addFavoriteToast.body'>
                part1
                <RouterLink to='/my-shopping/favorites' noMargin>
                  linkToFavorites
                </RouterLink>
                <ButtonLink
                  onClick={() => {
                    dispatch(showEditFavoriteModal(itemId))
                  }}
                >
                  chooseList
                </ButtonLink>
              </Trans>
            ),
            type: TOAST_TYPE.SUCCESS
          })
        )
      }
    }
  })

  // This is basically only for users with too many favorites.
  // If we fetch 1000 favorites and the user goes to a list without those favorites, we can assume that they are favorited.
  const { data: favoritesFromList } = useQuery(getFavoritesInList, {
    variables: { listId: favListId },
    skip: !favListId,
    fetchPolicy: 'cache-only'
  })

  const { data: currentList } = useFragment({
    fragment: gql`
      fragment currentList on ItemFavoriteList {
        user {
          objectId
        }
      }
    `,
    from: {
      __typename: 'ItemFavoriteList',
      objectId: favListId
    }
  })

  const isOwnList = currentList?.user?.objectId === parseUser?.id

  const isFavorited = (itemId) => {
    if (
      favoritesFromList?.itemWishlists?.some(
        ({ item, selected, active }) => item?.objectId === itemId && selected && active
      ) &&
      isOwnList
    )
      return true
    return data?.itemWishlists?.edges?.some(
      ({ node }) => node.item.objectId === itemId && node.selected && node.active
    )
  }

  const toggleFavorite = async (itemId) => {
    if (!parseUser) {
      await dispatch(signupAnonymous())
    }
    if (isFavorited(itemId)) {
      removeFavorite({
        variables: {
          itemId,
          isSV: locale === 'sv',
          isDE: locale === 'de',
          isEN: locale === 'en',
          isNL: locale === 'nl',
          isDA: locale === 'da',
          isPL: locale === 'pl',
          isFI: locale === 'fi',
          isFR: locale === 'fr'
        }
      })
    } else {
      addFavorite({
        variables: {
          itemId,
          region: region(),
          isSV: locale === 'sv',
          isDE: locale === 'de',
          isEN: locale === 'en',
          isNL: locale === 'nl',
          isDA: locale === 'da',
          isPL: locale === 'pl',
          isFI: locale === 'fi',
          isFR: locale === 'fr'
        }
      })
    }
  }
  const editListForFavorite = ({ itemId, listIds }) => {
    editFavorite({
      variables: {
        itemId,
        listIds
      }
    })
  }

  return {
    toggleFavorite,
    isFavorited,
    editListForFavorite,
    favorites: data?.itemWishlists?.edges
      .filter(({ node }) => node.selected && node.active)
      .map(({ node }) => node)
  }
}

export default useFavorites
