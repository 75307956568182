export const appendNextPath = (path) => (nextPath) => {
  const [pathname, search] = path.split('?')
  const newSearch = new URLSearchParams(search)
  newSearch.set('next-path', encodeURIComponent(nextPath))
  return `${pathname}${newSearch.size ? `?${newSearch.toString()}` : ''}`
}

export const extractNextPathFromLocation = (location) => {
  const encodedNextPath = location.search && new URLSearchParams(location.search).get('next-path')
  if (encodedNextPath) return decodeURIComponent(encodedNextPath)
  return location.state?.nextLocation
}
